<template>
  <div
    class="p-5 bg-black text-white"
  >
    <div class="grid grid-cols-5">
      <div class="top-left -mt-4">
        <a href="">
          <img
            class=""
            :src="require('@/assets/images/logo-v2.png')"
            alt="logo"
          >
        </a>
        <p class="pt-4 text-sm">
          User license
        </p>
        <h2 class="text-3xl">
          FACT SHEET
        </h2>
        <p class="pt-2 text-sm">
          <strong>  Disney + Fact Sheet </strong>
        </p>
        <p class="pb-2 text-sm">
          <strong>  disney_plus_fs.pdf </strong>
        </p>
        <p class="text-sm mb-4">
          View existing Fact Sheet
        </p>
        <base-button
          variant="btn-primary"
          class="mr-4"
          text="Click to open"
          @click="applySearch()"
        />
        <div class="middle-left pt-4">
          <p class="text-sm mb-4">
            To Create, choose Template
          </p>
          <ul class="flex">
            <li
              class="flex-1"
              @click="handleSelectTemplate(layout0)"
            >
              <img
                :src="require('@/assets/images/Template0.jpg')"
                alt="blank"
              >
            </li>
            <li
              class="flex-1"
              @click="handleSelectTemplate(layout1)"
            >
              <img
                :src="require('@/assets/images/Template1.jpg')"
                alt="template 1"
              >
            </li>
            <li
              class="flex-1"
              @click="handleSelectTemplate(layout2)"
            >
              <img
                :src="require('@/assets/images/Template2.jpg')"
                alt="template 2"
              >
            </li>
          </ul>
          <p class="py-4 text-sm">
            Or to add/modify content, click here:
          </p>
          <base-button
            variant="btn-primary"
            class="mr-4"
            text="Add a Widget"
            @click="addNewWidget('content')"
          />
          <base-button
            variant="btn-primary"
            class="mr-4"
            text="Add a Image"
            @click="addNewWidget('image')"
          />
        </div>
        <div class="bottom-left pt-6">
          <base-button
            class="mr-2 py-1"
            variant="btn-border-white"
            text="Save Fact Sheet..."
            @click="showHideRightBar(true, false)"
          />
          <base-button
            class="mr-0 py-1 text-gray-900"
            variant="btn-border-white"
            text="Publish Fact Sheet..."
            disabled
            @click="showHideRightBar(true, true)"
          />
        </div>
      </div>
      <div class="col-span-3 p-4 text-black bg-white overflow-hidden h-253 print-area-size">
        <div class="bg-gray-400 h-full">
          <grid-layout
            v-model:layout="layout"
            :col-num="12"
            :row-height="30"
            :is-draggable="true"
            :is-resizable="true"
            :is-mirrored="false"
            :vertical-compact="true"
            :margin="[0, 0]"
            :use-css-transforms="true"
          >
            <grid-item
              v-for="(item, itemIndex) in layout"
              :key="item.i"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              :class="clickedGridItemIndex === itemIndex ? 'z-10' : ''"
              drag-allow-from=".vue-draggable-handle"
              drag-ignore-from=".no-drag"
              @contextmenu="updateFocus(itemIndex)"
            >
              <image-widget
                v-if="item.widgetType === 'image'"
                v-model="item.content"
                :widget-index="itemIndex"
                @deleteWidget="handleDeleteWidget(item.i)"
              />
              <content-widget
                v-else
                v-model:heading="item.heading"
                v-model:content="item.content"
                :widget-index="itemIndex"
                @deleteWidget="handleDeleteWidget(item.i)"
              />
              <base-svg
                v-if="item.widgetType === 'content'"
                class="h-4 w-4 hover:text-white inline-block text-gray-900 cursor-pointer remove"
                src="icons/cross.svg"
                :svg-attributes="{
                  class: 'h-full w-full'
                }"
                tag="span"
                @click="handleDeleteWidget(item.i)"
              />
              <div
                v-if="item.widgetType === 'content'"
                class="text"
              >
                <base-svg
                  v-if="item.widgetType === 'content'"
                  class="h-4 w-4 hover:text-white inline-block text-gray-900 cursor-pointer vue-draggable-handle"
                  src="icons/drag-icon.svg"
                  :svg-attributes="{
                    class: 'h-full w-full'
                  }"
                  tag="span"
                />
              </div>
            </grid-item>
          </grid-layout>
        </div>
      </div>
      <div
        v-if="toShowRightBar"
        class="pt-0 ml-2 -mt-4 -mr-4 right-side"
      >
        <form
          class="bg-white p-4"
          action=""
        >
          <span
            v-if="isPublish"
            class="text-3xl text-black"
          >
            Publish
          </span>
          <span
            v-else
            class="text-3xl text-black"
          >
            Save
          </span>
          <div>
            <label class="text-black"><b> Title </b>
              <span class="text-red-500">*</span>
            </label>
            <input
              class="w-full px-2 py-1 border-none bg-red-50 text-sm text-black mt-2 mb-4"
              placeholder="Enter Title"
              type="text"
            >
          </div>
          <div>
            <label class="text-black"> <b> Full Name </b>
              <span class="text-red-500">*</span>
            </label>
            <input
              class="w-full px-2 py-1 border-none bg-red-50 text-sm text-black mt-2 mb-4"
              placeholder="Enter Full Name"
              type="text"
            >
          </div>
          <div>
            <label class="text-black"> <b> Meta Data </b>
              <span class="text-red-500">*</span>
            </label>
            <input
              class="w-full px-2 py-1 border-none bg-red-50 text-sm text-black mt-2 mb-4"
              placeholder="Enter Meta Data"
              type="text"
            >
          </div>
          <div>
            <label class="text-black"> <b> Display Order </b>
              <span class="text-red-500">*</span>
            </label>
            <input
              class="w-full px-2 py-1 border-none bg-red-50 text-sm text-black mt-2 mb-4"
              placeholder="Enter Display Order (digits only)"
              type="text"
            >
          </div>
          <div>
            <label class="text-black"> <b> Iteration </b>
              <span class="text-red-500">*</span>
            </label>
            <base-select
              class="text-sm max-w-full w-40 text-left mr-3 text-black bg-white mt-2 mb-5"
              text-container-class="w-full h-7 inline-block p-1 text-left"
              :btn-props="{
                class: 'focus:outline-none text-primary-gray rounded-sm border border-custom-gray-8 text-custom-gray-7 flex'
              }"
              dropdown-class="max-h-32 rounded-sm py-1"
              list-item-class="cursor-default select-none relative py-1 pl-2 pr-9 hover:bg-custom-gray-2"
              :options="iterationOption"
              use-selected-option-icon
            />
          </div>
          <div v-if="isPublish">
            <label class="text-black"> <b> Choose a PDF </b>
              <span class="text-red-500">*</span>
            </label>
            <input
              class="w-full px-2 py-1 border-none bg-red-50 text-sm text-black mt-2 mb-4"
              placeholder="Enter Title"
              type="file"
            >
          </div>
          <div class="p-2">
            <base-button
              class="mr-4 py-1 w-full"
              variant="btn-green"
              :text="isPubish ? 'Publish' : 'Save'"
              @click="uploadFiles()"
            />
          </div>
          <div class="p-2">
            <base-button
              class="mr-4 py-1 w-full"
              variant="btn-primary"
              text="Close"
              @click="showHideRightBar(false, null)"
            />
          </div>
        </form>
      </div>
    </div>
    <base-modal
      v-model="toShowModal"
      overlay-class="bg-gray-900 opacity-60"
      popup-class="bg-white text-left overflow-hidden shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
    >
      <template #modalHeader>
        <header class="px-2.5 py-2 bg-custom-gray-10 flex justify-between items-center">
          <span class="text-black text-sm font-semibold">
            {{ deleteModal ? 'Delete Widget' : 'Select Template' }}
          </span>
          <base-svg
            class="h-5 w-5 text-custom-gray-7 inline-block hover:text-gray-900 cursor-pointer"
            src="icons/cross.svg"
            :svg-attributes="{
              class: 'h-full w-full'
            }"
            tag="span"
            @click="toggelModal(false)"
          />
        </header>
      </template>
      <template #modalBody>
        <div class="px-4 py-3">
          <div class="text-justify text-sm mb-2 leading-5">
            Are you sure you want to {{ deleteModal ? 'Delete this widget' : 'create this template?' }}
          </div>
          <div class="text-right">
            <base-button
              class="mr-3"
              variant="btn-primary"
              text="Yes"
              @click="deleteModal ? deleteWidget() : selectTemplate()"
            />
            <base-button
              variant="btn-link"
              text="No"
              @click="toggelModal(false)"
            />
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue';
import { GridItem, GridLayout } from 'vue-grid-layout';

export default {
    components: {
        GridItem,
        GridLayout,
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        ContentWidget: defineAsyncComponent(() => import('@/components/fact-sheets/ContentWidget.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        ImageWidget: defineAsyncComponent(() => import('@/components/fact-sheets/ImageWidget.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },
    data () {
        return {
            toShowModal: false,
            deleteModal: false,
            toShowRightBar: false,
            isPublish: false,
            iterationFiltersMap: [
                {
                    label: 'Classic',
                    key: 'Classic'
                },
                {
                    label: 'Movie',
                    key: 'Movie'
                },
                {
                    label: 'Retro',
                    key: 'Retro'
                },
                {
                    label: 'Television',
                    key: 'Television'
                },
                {
                    label: 'Ultimate',
                    key: 'Ultimate'
                }
            ],
            widgetToBeDeleted: '',
            widgetDefaultWidth: 4,
            widgetDefaultHeight: 3,
            layoutToBeLoaded: [],
            layout: [],
            layout0: [
                { x: 0, y: 0, w: 4, h: 3, i: '0', widgetType: 'image' }
            ],
            layout1: [
                { x: 0, y: 0, w: 9, h: 10, i: '0', widgetType: 'image' },
                { x: 0, y: 7, w: 12, h: 7, i: '1', widgetType: 'content' },
                { x: 9, y: 2, w: 3, h: 5, i: '2', widgetType: 'content' },
                { x: 9, y: 0, w: 3, h: 5, i: '3', widgetType: 'content' },
                { x: 0, y: 4, w: 12, h: 9, i: '4', widgetType: 'content' },
                { x: 0, y: 10, w: 12, h: 7, i: '5', widgetType: 'content' }
            ],

            layout2: [
                { x: 0, y: 0, w: 5, h: 6, i: '0', content: '', heading: '', widgetType: 'image' },
                { x: 4, y: 6, w: 4, h: 6, i: '1', content: '', heading: '', widgetType: 'content' },
                { x: 5, y: 0, w: 7, h: 6, i: '2', content: '', heading: '', widgetType: 'image' },
                { x: 3, y: 12, w: 9, h: 6, i: '3', content: '', heading: '', widgetType: 'content' },
                { x: 0, y: 6, w: 4, h: 6, i: '4', content: '', heading: '', widgetType: 'content' },
                { x: 0, y: 12, w: 3, h: 6, i: '5', content: '', heading: '', widgetType: 'content' },
                { x: 0, y: 18, w: 5, h: 5, i: '6', content: '', heading: '', widgetType: 'content' },
                { x: 5, y: 18, w: 7, h: 5, i: '7', content: '', heading: '', widgetType: 'content' },
                { x: 8, y: 6, w: 4, h: 6, i: '8', content: '', heading: '', widgetType: 'content' },
                { x: 0, y: 23, w: 3, h: 4, i: '9', content: '', heading: '', widgetType: 'content' },
                { x: 3, y: 23, w: 9, h: 4, i: '10', content: '', heading: '', widgetType: 'content' },
                { x: 0, y: 27, w: 12, h: 6, i: '11', content: '', heading: '', widgetType: 'content' }
            ],

            clickedGridItemIndex: null
        };
    },
    computed: {
        iterationOption () {
            return this.iterationFiltersMap.map(filter => filter.label);
        }
    },
    mounted () {
        this.layout = this.layout0.map(item => {
            return {
                ...item,
                content: '',
                heading: ''
            };
        });
    },
    methods: {
        uploadFiles () {
            // console.log()
        },
        toggelModal (val) {
            this.toShowModal = val;
        },
        showHideRightBar (val, val2) {
            this.isPublish = val2;
            this.toShowRightBar = val;
        },
        selectTemplate () {
            this.layout = [];
            this.layout = this.layoutToBeLoaded.map(item => {
                return {
                    ...item,
                    content: '',
                    heading: ''
                };
            });
            this.toggelModal(false);
        },
        handleSelectTemplate (layout) {
            this.toggelModal(true);
            this.layoutToBeLoaded = layout;
        },
        updateFocus (index) {
            this.clickedGridItemIndex = index;
        },
        // to be continued
        addNewWidget (val) {
            const abc = {
                x: (this.layout.length * this.widgetDefaultWidth) % 12,
                y: this.layout.length + 12,
                w: this.widgetDefaultWidth,
                h: this.widgetDefaultHeight,
                i: (this.layout.length).toString(),
                content: '',
                heading: '',
                widgetType: val === 'content' ? 'content' : 'image'
            };
            this.layout.push(abc);
            window.dispatchEvent(new Event('resize'));
        },
        deleteWidget () {
            const index = this.layout.map(item => item.i).indexOf(this.widgetToBeDeleted);
            this.layout.splice(index, 1);
            this.toggelModal(false);
        },
        handleDeleteWidget (val) {
            this.deleteModal = true;
            this.toggelModal(true);
            this.widgetToBeDeleted = val;
        }
    }
};

</script>

<style>
@layer components {
  .vue-grid-item  {
    @apply bg-white;
  }
}

@media print {
  .top-left, .right-side, .remove, .vue-draggable-handle, title {
    display: none;
  }
}

.print-area-size {
    width: 21cm;
    height: 26.7cm;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 7px;
    left: 7px;
    padding: 0px 0px 6px 0;
    cursor: pointer;
}

.remove {
    position: absolute;
    right: 10px;
    top: 6px;
    cursor: pointer;
}
</style>
